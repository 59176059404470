import { useRouteError, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from "@mui/material";

import { PathConstants } from '../utils/nav';
import { PageHeader } from './_PageHeader';

type RouteError = {
    statusText?: string,
    message?: string,
  }

export const ErrorPage = () => {     
    const error = useRouteError() as RouteError
    console.log(error)

    const navigate = useNavigate();

    const ctaClick = (route: string) => {
        navigate(route);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            <PageHeader
                title="Ooops, we can't find what you're looking for"
                subtitle="We have the details in our logs." 
            />
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => ctaClick(PathConstants.HOME)}
            >
                Return to Branco
            </Button>
        </Box>
    );
};