import React, {useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow, Typography,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {StepCard} from "./StepCard";
import {DeleteOutlined} from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {StepSuggestionCard} from "./StepSuggestionCard";

interface ActionPlanItemCardProps {
    plan: ActionItemInfo,
    stepSuggestions: Array<ActionItemStepSuggestionInfo>,
    onDelete: (item: ActionItemInfo) => void,
    onEdit: () => void,
    onActionItemCompleted: (item: ActionItemInfo, isCompleted: boolean) => void,
    onStepCompleted: (step: ActionItemStepInfo, isCompleted: boolean) => void,
    onAcceptSuggestionStep: (item: ActionItemInfo, step: ActionItemStepSuggestionInfo, isAccepted: boolean) => void,
    showActiveOnly: boolean,
    viewLevelDetails: boolean
}

export const ActionPlanItemCard = ({
                                       plan,
                                       stepSuggestions,
                                       onDelete,
                                       onEdit,
                                       onActionItemCompleted,
                                       onStepCompleted,
                                       onAcceptSuggestionStep,
                                       showActiveOnly,
                                       viewLevelDetails
                                   }: ActionPlanItemCardProps) => {
    const [actionPlan] = useState<ActionItemInfo>(plan);

    function handleEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onEdit();
    }

    function handleActionItemCompleted(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked; // Get the current value of the checkbox
        onActionItemCompleted(actionPlan, isChecked);
    }

    function handleAcceptSuggestionStep(stepSuggestion: ActionItemStepSuggestionInfo, isAccepted: boolean) {
        onAcceptSuggestionStep(actionPlan, stepSuggestion, isAccepted);
    }

    function handleDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onDelete(plan);
    }

    // List of action items and action item suggestions
    const actionPlanContent = (
        <Table sx={{tableLayout: "auto"}}>
            <TableBody>
                <TableRow sx={{
                    // Removes the bottom border of the last table row
                    "&:last-child td, &:last-child th": {
                        borderBottom: "none",
                    },
                    display: "flex",
                }}>
                    <TableCell size="small" width="5%" sx={{
                        paddingY: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Checkbox id={`item-check- ${actionPlan.user_action_plan_id}`}
                                  checked={!!actionPlan.user_action_plan_date_completed}
                                  onClick={(event) => event.stopPropagation()}
                                  onChange={handleActionItemCompleted}/>
                    </TableCell>
                    <TableCell align="left" sx={{
                        padding: 0, display: "flex",
                        alignItems: "center"
                    }}>
                        <Stack direction={"column"} justifyContent="flex-end" spacing={0}>
                            <Typography variant="h5">
                                {actionPlan.user_action_plan_goal}
                            </Typography>
                            {viewLevelDetails && 
                                <i>
                                    Priority: {actionPlan.user_action_plan_priority} -  
                                    Business value: {actionPlan.user_action_plan_business_value}
                                </i>
                            }
                        </Stack>
                    </TableCell>
                    {/*Placeholder cell to fill in the space and push the next cell to the right*/}
                    <TableCell sx={{flexGrow: 1}}/>
                    <TableCell align="right" sx={{
                        paddingY: 0,
                        margin: 0,
                        whiteSpace: "nowrap",
                        width: "1%",
                    }}>
                        <Stack direction={"row"} justifyContent="flex-end" spacing={1}>
                            <Button color="info" onClick={handleDelete}
                                    sx={{margin: 0, padding: 0, minWidth: "auto"}}>
                                <DeleteOutlined fontSize="medium" color={"error"}/>
                            </Button> 
                            <Button color="info" onClick={handleEdit}
                                    sx={{margin: 0, padding: 0, minWidth: "auto"}}>
                                <EditOutlinedIcon fontSize="medium" color={"primary"}/>
                            </Button>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const renderStepCards = () =>
        (plan.user_action_plan_steps || []).map((itemStep) => {
                const visible = !(showActiveOnly && itemStep.user_action_plan_step_date_completed);
                return visible && (<StepCard
                    stepInfo={itemStep}
                    key={itemStep.user_action_plan_step_id}
                    onStepCompleted={onStepCompleted}
                    viewLevelDetails={viewLevelDetails}
                />);
            }
        )

    const renderStepSuggestionCards = () =>
        (stepSuggestions || []).map((itemSuggestedStep) => (
            <StepSuggestionCard
                stepSuggestionInfo={itemSuggestedStep}
                onAcceptSuggestionStep={handleAcceptSuggestionStep}
                key={itemSuggestedStep.user_action_plan_step_suggestion_id}
            />
        ));

    // List of steps and step suggestions
    const stepsContent = (
        <Stack spacing={2}>
            <Table>
                <TableBody>
                    {renderStepCards()}
                    {renderStepSuggestionCards()}
                </TableBody>
            </Table>

        </Stack>
    );

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                id="panel-header"
            >
                {actionPlanContent}
            </AccordionSummary>
            <AccordionDetails sx={{marginLeft: 2, paddingY: 0}}>
                {stepsContent}
            </AccordionDetails>
        </Accordion>
    );
};