import React, { useContext } from "react";
import { Box, Grid2 } from "@mui/material";

import "./SettingsPage.css";
import { UserContext } from "../../utils/context";

import { PageHeader } from "../_PageHeader";
import { SlackIntegrate } from "./SlackIntegrate";

export const SettingsPage = () => {
  const [user] = useContext(UserContext);

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Grid2>
        <PageHeader
          title="Settings"
          subtitle="Setup your system and company key details."
        />
      </Grid2>
      <Grid2
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <SlackIntegrate />
      </Grid2>
    </Box>
  );
};
