import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { PathConstants } from '../../utils/nav';

import { CompanyTool } from "../../services/api/schemas/CompanyTool";
import { createSlack } from "../../services/api/CompanyTool";
import { PageHeader } from "../_PageHeader";

const SlackOAuthRedirect = () => {
    const location = useLocation();
    const [done, setDone] = useState<boolean>(false);
    const [message, setMessage] = useState("Processing Slack integration...");

    const [companyTool, setCompanyTool] = useState<CompanyTool | null>();
    const navigate = useNavigate();

    const ctaClick = (route: string) => {
        navigate(route);
    };

    const getQueryParams = () => {
      return new URLSearchParams(location.search);
    };

  useEffect(() => {
    const queryParams = getQueryParams();
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    if (!code || !state) {
      setMessage("Missing required OAuth parameters. Please try again.");
      return;
    }

    const sendOAuthRequest = async () => {
      try {
        //await createSlack(code, state);
        await createSlack(code, state).then((value) => {
            setCompanyTool(value);
            setMessage("Integration with Slack was successful!");
            setDone(true);
          });
      } catch (error) {
        setMessage(
          `Failed to integrate with Slack: ${error}. Please try again.`
        );
      }
    };

    sendOAuthRequest();
  }, [location.search]);

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <PageHeader
        title="Slack Integration"
        subtitle={message}
      />
      {companyTool ? 
        (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => ctaClick(PathConstants.HOME)}
          >
            Return to Branco
        </Button>
        )
        :
        (done && 
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => ctaClick(PathConstants.SETTINGS)}
            >
              Return to Settings
          </Button>
        )
      }
    </Box>
  );
};

export default SlackOAuthRedirect;
