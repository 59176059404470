import { get, post } from "./helpers";
import { User } from "./schemas/User";

export const getCurrentUser = async (): Promise<User> => {
  return await get<User>("/users/me");
};

export const getUser = async (userId: number): Promise<User> => {
  return await get<User>(`/users/${userId}`);
};

export const createUser = async (
  userName: string,
  userEmail: string
): Promise<User> => {
  return await post<User>("/users", {
    user_name: userName,
    user_email: userEmail,
  });
};
