import React, { useState, useEffect, useContext } from "react";
import { Box, Grid2, Avatar, FormControlLabel, Switch, Chip, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";

import { UserContext, SkillsContext, CompetenciesContext } from "../../utils/context.js";

import { PageHeader } from "../_PageHeader";

import { convertDateToShort } from "../../utils/monthNames";
import {
  getFeedbacksProvided,
  getFeedbacksReceived,
  getFeedbacksSelf,
} from "../../services/FeedbackPublicAPI";


enum feedbackType {
  Received,
  Provided,
  Self,
}

export const FeedbackPage = () => {
  const [user] = useContext(UserContext);
  const [skills] = useContext(SkillsContext);
  const [competencies] = useContext(CompetenciesContext);
  const [feedbacks, setFeedback] = useState<FeedbackInfo[]>([]);
  const [tabValue, setTabValue] = React.useState<feedbackType>(
    feedbackType.Received
  );
  const [highQualityOnlyFeedback, setViewStatus] = useState<boolean>(true);

  const getDisplayNameSkill = (career_step_skill_id: number) => {
    const foundSkill = skills.find(
      (feedback_details:FeedbackInfo) => feedback_details.career_step_skill_id === career_step_skill_id
    );
    return foundSkill ? foundSkill.skill.skill_name : ''; 
  };
  const getDisplayNameCompetency = (career_step_competency_id: number) => {
    const foundCompetency = competencies.find(
      (feedback_details:FeedbackInfo) => feedback_details.career_step_competency_id === career_step_competency_id
    );
    return foundCompetency ? foundCompetency.competency.competency_name : ''; 
  };
  
  useEffect(() => {
    try {
      if (user){
        fetchFeedbacks(
          feedbackType.Received,
          highQualityOnlyFeedback
        );
      }
    } catch (err) {
      console.error("Failed to retrieve feedback. ", err);
    }
  }, [user]);

  const fetchFeedbacks = async (
    type: feedbackType,
    highQualityOnlyFeedback: boolean
  ) => {
    var results: FeedbackInfo[] = [];

    switch (type) {
      case feedbackType.Received: {
        results = await getFeedbacksReceived(user.user_id);
        break;
      }
      case feedbackType.Provided: {
        results = await getFeedbacksProvided(user.user_id);
        break;
      }
      case feedbackType.Self: {
        results = await getFeedbacksSelf(user.user_id);
        break;
      }
    }

    if (!highQualityOnlyFeedback) {
      setFeedback(results);
    } else {
      var results_not_generic: FeedbackInfo[] = [];
      results.forEach((element) => {
        if (element.feedback_notes) results_not_generic.push(element);
      });

      setFeedback(results_not_generic);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (user) {
      fetchFeedbacks(newValue, highQualityOnlyFeedback);
    }
  };
  const viewStatusChange = async () => {
    setViewStatus(!highQualityOnlyFeedback);
    if (user) {
      fetchFeedbacks(tabValue, !highQualityOnlyFeedback);
    }
  };

  const chipStyle = {
    border: "1px solid grey",
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Grid2>
        <PageHeader
          title="Feedback"
          subtitle="Navigate the feedback you receive and provided."
        />
      </Grid2>
      <Grid2 container sx={{ my: 2 }} justifyContent="flex-center">
        <Grid2 size={8} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Received" />
            <Tab label="Provided" />
            <Tab label="Self" />
          </Tabs>
        </Grid2>
        <Grid2
          size={4}
          container
          justifyContent="flex-end"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <FormControlLabel
            label="high quality only"
            control={<Switch id="feedback-viewStatusChange-switch" defaultChecked onClick={viewStatusChange} />}
          />
        </Grid2>
      </Grid2>
      <Grid2 container sx={{ my: 1 }} justifyContent="flex-start">
        {feedbacks && feedbacks.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell align="center" size="small"></TableCell>*/}
                  <TableCell align="center" size="small"></TableCell>
                  <TableCell align="center" size="small"></TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="left">Feedback</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbacks.map((feedback) => (
                  <TableRow key={feedback.feedback_id}>
                    {/*<TableCell align="center" size="small">
                      <Avatar
                        sizes="small"
                        alt={
                          tabValue === feedbackType.Received
                            ? feedback.feedback_reviewer_user_id ? feedback.feedback_reviewer_user_id.toString() : null
                            : feedback.feedback_receiver_user_id ? feedback.feedback_receiver_user_id.toString() : null
                        }
                        //src="/static/images/avatar/7.jpg"
                        sx={{ width: 25, height: 25 }}
                      >
                        {tabValue === feedbackType.Received
                          ? feedback.feedback_reviewer_user_id ? feedback.feedback_reviewer_user_id.toString() : null
                          : feedback.feedback_receiver_user_id ? feedback.feedback_receiver_user_id.toString() : null}
                      </Avatar>
                    </TableCell>*/}
                    <TableCell align="center" size="small">
                      {feedback.feedback_rating === 5 && (
                        <Tooltip title="100% (5/5)" arrow><StarBorderOutlinedIcon /></Tooltip>
                      )}
                      {feedback.feedback_rating === 4 && (
                        <Tooltip title="80% (4/5)" arrow><ThumbUpOutlinedIcon /></Tooltip>
                      )}
                      {feedback.feedback_rating === 3 && (
                        <Tooltip title="60% (3/5)" arrow><SentimentNeutralIcon /></Tooltip>
                      )}
                      {feedback.feedback_rating === 2 && (
                        <Tooltip title="40% (2/5)" arrow><ThumbDownOutlinedIcon /></Tooltip>
                      )}
                      {feedback.feedback_rating === 1 && (
                        <Tooltip title="20% (1/5)" arrow><WarningAmberOutlinedIcon /></Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {feedback.career_step_skill_id && (
                        <Tooltip title="Skill" arrow><HandymanOutlinedIcon /></Tooltip>
                      )}
                      {feedback.career_step_competency_id && (
                        <Tooltip title="Competency (soft skill)" arrow><Diversity3OutlinedIcon /></Tooltip>
                      )}
                      {feedback.kpi_id && (
                        <Tooltip title="Team impact" arrow><ModeStandbyOutlinedIcon /></Tooltip>
                      )}
                      {feedback.user_action_plan_step_id && (
                        <Tooltip title="Action plan" arrow><AssignmentTurnedInOutlinedIcon /></Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        color="primary"
                        variant="outlined"
                        label={`${convertDateToShort(feedback.feedback_date)}`}
                        size="small"
                        style={chipStyle}
                        sx={{ marginBottom: 0 }}
                      />
                    </TableCell>
                    <TableCell align="left">
                    {feedback.career_step_skill_id && (
                      <Chip
                        color="primary"
                        variant="outlined"
                        label={`${getDisplayNameSkill(feedback.career_step_skill_id)}`}
                        size="small"
                        style={chipStyle}
                        sx={{ marginBottom: 0 }}
                      />
                      )}
                      {feedback.career_step_competency_id && (
                      <Chip
                        color="primary"
                        variant="outlined"
                        label={`${getDisplayNameCompetency(feedback.career_step_competency_id)}`}
                        size="small"
                        style={chipStyle}
                        sx={{ marginBottom: 0 }}
                      />
                      )} {feedback.feedback_notes} 
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid2>
    </Box>
  );
};
