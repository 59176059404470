import * as React from 'react';
import { useEffect, useContext } from "react";

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Button, Icon, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { UserContext, CompetenciesContext } from "../../utils/context.js";
import { UserCareerStepCompetency } from '../../services/api/schemas/UserCareerStepCompetency';
import { getUserRatings } from '../../services/DashboardAPI';
import { getCompetencyRating } from '../../services/api/CompetencyRating';
import { CompetencyRating } from '../../services/api/schemas/CompetencyRating';

export default function ExpectationsCompetencies() {
  const [user] = useContext(UserContext);
  const [competencies] = useContext(CompetenciesContext);
  
  const [strengthsopps, setUserRatings] = React.useState<StrengthOpportunityInfo[]>([]);
  const [competency_ratings, setCompetencyRatings] = React.useState<CompetencyRating[]>([]);


  const getUserCompetencyRating = (career_step_competency_id: number) => {
    const foundCompetency = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.career_step_competency_id === career_step_competency_id
    );
    return foundCompetency ? foundCompetency.average_rating : 0; 
  };

  const fetchUserRatings = async (user_id: number) => {
    await getUserRatings(user_id).then((results) => {
      const all_results = results as StrengthOpportunityInfo[] || [];
      setUserRatings(all_results);
    }).catch((err) => {
      console.error("Failed to fetch user ratings: ", err);
    });
  };
  

  const fetchCompetencyRating = async (competency_id: number) => {
    await getCompetencyRating(competency_id).then((results) => {
      results.sort((one, two) => (one.competency_rating_level > two.competency_rating_level ? -1 : 1));
      setCompetencyRatings(results);
    }).catch((err) => {
      console.error("Failed to fetch competency ratings: ", err);
    });
  };

  useEffect(() => {
      try {
        if (user && competencies){
          fetchUserRatings(user.user_id);
        }
      } catch (err) {
        console.error("Failed to retrieve competencies expectations. ", err);
      }
    }, [user, competencies]);

        // POPUP ratings
        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          try {
            const {value} = event.currentTarget;
            var selected_competency_id: number = +value;
            fetchCompetencyRating(selected_competency_id);
            } catch (err) {
            console.error("Failed to retrieve competency ID from 'i' button (for rating). ", err);
          }
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
    
  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      {competencies && competencies.length > 0 && (
        <CardContent>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
          <Diversity3OutlinedIcon /> Competencies expected
          </Typography>
          {competencies.map((comp:UserCareerStepCompetency) => (
            <Stack
              key={comp.career_step_competency_id}
              direction="row"
              sx={{ alignItems: 'center', gap: 2, pb: 2 }}
            >
              {comp.must_have ? (
                <Tooltip title="Must have" arrow>
                  <Icon sx={{ width: 24, height: 24 }}><StarOutlineOutlinedIcon /></Icon>
                </Tooltip>
              ):(
                <Icon sx={{ width: 24, height: 24 }}></Icon>
              )}
              <Stack sx={{ gap: 1, flexGrow: 1 }}>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    {comp.competency.competency_name}
                    <Button aria-describedby={id} value={comp.competency_id} size="small" onClick={handleClick}>
                      <InfoOutlinedIcon />
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      {competency_ratings && competency_ratings.length > 0 && (
                        <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/*<TableCell align="center" size="small"></TableCell>*/}
                              <TableCell align="center" size="small"><b>Level</b></TableCell>
                              <TableCell align="left"><b>Definition</b></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {competency_ratings.map((competency_rating) => (
                              <TableRow key={competency_rating.competency_rating_level}>
                                <TableCell align="center">
                                  {competency_rating.competency_rating_level*20}%
                                </TableCell>
                                <TableCell align="left">
                                  {competency_rating.competency_rating_definition}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Popover>
                  </Typography>
                  {getUserCompetencyRating(comp.career_step_competency_id) > 0 ?
                    (<Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      rated {(getUserCompetencyRating(comp.career_step_competency_id)/5*100).toFixed(0)}%, 
                      expected {(comp.expected_rating/5*100).toFixed(0)}%
                    </Typography>)
                    :
                    (<Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      not rated yet, expected {(comp.expected_rating/5*100).toFixed(0)}%
                    </Typography>)
                  }
                </Stack>
                <Box sx={{ width: comp.expected_rating/5 }}>
                  <LinearProgress
                    variant="buffer"
                    aria-label="value"
                    value={getUserCompetencyRating(comp.career_step_competency_id)/comp.expected_rating*100}
                    valueBuffer={getUserCompetencyRating(comp.career_step_competency_id)/comp.expected_rating*100}
                  />
                </Box>
              </Stack>
            </Stack>
          ))}
        </CardContent>
      )}
    </Card>
  );
}