const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;


export const getUserStrengths = async (UserId: number) => {
    const params = {user_id: UserId.toString()};

    const response = await fetch(
      `${PUBLIC_API_URL}/dashboard/user_strengths?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`getUserStrengths request failed with status ${response.status}: ${response.statusText}`);
    }
    return response.json();
};

export const getUserOpportunities = async (UserId: number) => {
  const params = {user_id: UserId.toString()};

  const response = await fetch(
    `${PUBLIC_API_URL}/dashboard/user_opportunities?` +
    new URLSearchParams(params).toString(),
  {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  if (response.status !== 200) {
      // Throw an error if the status is not 200
      throw new Error(`getUserOpportunities request failed with status ${response.status}: ${response.statusText}`);
  }
  return response.json();
};

export const getUserRatings = async (UserId: number) => {
  const params = {user_id: UserId.toString()};

  const response = await fetch(
    `${PUBLIC_API_URL}/dashboard/user_ratings?` +
    new URLSearchParams(params).toString(),
  {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  if (response.status !== 200) {
      // Throw an error if the status is not 200
      throw new Error(`getUserRatings request failed with status ${response.status}: ${response.statusText}`);
  }
  return response.json();
};

export const getUserTrends = async (UserId: number, TrendType: string) => {
  const params = {user_id: UserId.toString(), time_serie_type: TrendType};

  const response = await fetch(
    `${PUBLIC_API_URL}/dashboard/user_time_series?` +
    new URLSearchParams(params).toString(),
  {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  if (response.status !== 200) {
      // Throw an error if the status is not 200
      throw new Error(`getUserTrends request failed with status ${response.status}: ${response.statusText}`);
  }
  return response.json();
};
