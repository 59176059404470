import { get } from "./helpers";
import { CompetencyRating } from "./schemas/CompetencyRating";

export const getCompetencyRating = async (
  competency_id: number,
): Promise<CompetencyRating[]> => {
  try {
    const params = {competency_id: competency_id.toString()};
    return await get<CompetencyRating[]>("/competency_ratings/competency_id?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};
