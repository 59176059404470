import {get} from "./api/helpers";

const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

export const getActionPlanSuggestions = async (
    userId: number
): Promise<ActionItemSuggestionInfo[]> => {
    return await get<ActionItemSuggestionInfo[]>(
        "/user_action_plans/suggestions?",
        new URLSearchParams({
            user_id: userId.toString(),
        })
    );
};

export const acceptActionPlanSuggestion = async (
    action_plan_suggestion_id: number
) => {
    const response = await fetch(
        `${PUBLIC_API_URL}/user_action_plans/suggestions/${action_plan_suggestion_id}/accept`,
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: null,
        }
    );
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`acceptActionPlanSuggestion request failed with status ${response.status}: ${response.statusText}`);
    }

    return response.json();
};

export const rejectActionPlanSuggestion = async (
    action_plan_suggestion_id: number
) => {
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/suggestions/${action_plan_suggestion_id}/reject`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: null,
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const acceptActionPlanStepSuggestion = async (
    action_plan_suggestion_id: number,
    action_plan_step_suggestion_id: number
) => {
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/suggestions/${action_plan_suggestion_id}/steps/${action_plan_step_suggestion_id}/accept`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: null,
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const rejectActionPlanStepSuggestion = async (
    action_plan_suggestion_id: number,
    action_plan_step_suggestion_id: number
) => {
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/suggestions/${action_plan_suggestion_id}/steps/${action_plan_step_suggestion_id}/reject`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: null,
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};
