import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer, { drawerClasses } from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";

import MenuButton from "./MenuButton";
import MenuContent from "./MenuContent";
import AlertCard from "../_AlertCard";

import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import FirebaseAuth from "../../services/authentication/firebase/Authentication";
import { PathConstants } from "../../utils/nav";
import Notifications from "./Notifications";

interface SideMenuMobileProps {
  open: boolean | undefined;
  toggleDrawer: (newOpen: boolean) => () => void;
}

export default function SideMenuMobile({
  open,
  toggleDrawer,
}: SideMenuMobileProps) {
  const fa = new FirebaseAuth();
  const userInfo = fa.getUserInfo();
  const auth = getAuth();
  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out");
      navigate(PathConstants.SIGN_IN);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onClick={toggleDrawer(false)}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: "none",
          backgroundColor: "background.paper",
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: "70dvw",
          height: "100%",
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Stack
            direction="row"
            sx={{ gap: 1, alignItems: "center", flexGrow: 1, p: 1 }}
          >
            <Avatar
              sizes="small"
              alt={
                userInfo
                  ? `${
                      userInfo.displayName ? userInfo.displayName : userInfo.uid
                    }`
                  : "?"
              }
              //src="/static/images/avatar/7.jpg"
              sx={{ width: 24, height: 24 }}
            />
            <Typography component="p" variant="h6">
              {userInfo
                ? `${
                    userInfo.displayName ? userInfo.displayName : userInfo.uid
                  }`
                : "Not logged in"}
            </Typography>
          </Stack>
          {/*
          <MenuButton showBadge>
            <NotificationsRoundedIcon />
          </MenuButton>
          <Notifications />
          */}
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent />
          <Divider />
        </Stack>
        {/*
        <AlertCard 
        title="Plan about to expire" 
        offer="Enjoy 10% off when renewing your plan today." 
        cta="Get the discount" 
        route={PathConstants.ACCOUNT} />
        */}
        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={logOut}
            startIcon={<LogoutRoundedIcon />}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}
