import { get, post } from "./helpers";
import { CompanyTool } from "./schemas/CompanyTool";

export const getCompanyTool = async (
  company_id: number,
  tool_id: number
): Promise<CompanyTool | null> => {
  try {
    return await get<CompanyTool>(`/company_tools/${company_id}/${tool_id}`);
  } catch (err) {
    console.error(err);
    return null;
  }  
};

export const createSlack = async (
  code: string,
  state: string
): Promise<CompanyTool> => {
  return await post<CompanyTool>("/company_tools/slack", { code, state });
};
