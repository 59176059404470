import * as React from 'react';
import { useEffect, useContext } from "react";

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Icon, Tooltip } from '@mui/material';

import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';

import { UserContext, CompetenciesContext } from "../../utils/context.js";
import { UserCareerStepCompetency } from '../../services/api/schemas/UserCareerStepCompetency';
import { getUserRatings } from '../../services/DashboardAPI';

export default function ExpectationsCompetencies() {
  const [user] = useContext(UserContext);
  const [competencies] = useContext(CompetenciesContext);
  const [strengthsopps, setUserRatings] = React.useState<StrengthOpportunityInfo[]>([]);

  const getUserCompetencyRating = (career_step_competency_id: number) => {
    const foundCompetency = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.career_step_competency_id === career_step_competency_id
    );
    return foundCompetency ? foundCompetency.average_rating : 0; 
  };

  const fetchUserRatings = async (user_id: number) => {
    await getUserRatings(user_id).then((results) => {
      const all_results = results as StrengthOpportunityInfo[] || [];
      setUserRatings(all_results);
    }).catch((err) => {
      console.error("Failed to fetch user ratings: ", err);
    });
  };
  
  useEffect(() => {
      try {
        if (user && competencies){
          fetchUserRatings(user.user_id);
        }
      } catch (err) {
        console.error("Failed to retrieve competencies expectations. ", err);
      }
    }, [user, competencies]);
    
  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      {competencies && competencies.length > 0 && (
        <CardContent>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
          <Diversity3OutlinedIcon /> Competencies expected
          </Typography>
          {competencies.map((comp:UserCareerStepCompetency) => (
            <Stack
              key={comp.career_step_competency_id}
              direction="row"
              sx={{ alignItems: 'center', gap: 2, pb: 2 }}
            >
              {comp.must_have ? (
                <Tooltip title="Must have" arrow>
                  <Icon sx={{ width: 24, height: 24 }}><StarOutlineOutlinedIcon /></Icon>
                </Tooltip>
              ):(
                <Icon sx={{ width: 24, height: 24 }}></Icon>
              )}
              <Stack sx={{ gap: 1, flexGrow: 1 }}>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    {comp.competency.competency_name}
                  </Typography>
                  {getUserCompetencyRating(comp.career_step_competency_id) > 0 ?
                    (<Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      rated {(getUserCompetencyRating(comp.career_step_competency_id)/5*100).toFixed(0)}%, 
                      expected {(comp.expected_rating/5*100).toFixed(0)}%
                    </Typography>)
                    :
                    (<Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      not rated yet, expected {(comp.expected_rating/5*100).toFixed(0)}%
                    </Typography>)
                  }
                </Stack>
                <Box sx={{ width: comp.expected_rating/5 }}>
                  <LinearProgress
                    variant="buffer"
                    aria-label="value"
                    value={getUserCompetencyRating(comp.career_step_competency_id)/comp.expected_rating*100}
                    valueBuffer={getUserCompetencyRating(comp.career_step_competency_id)/comp.expected_rating*100}
                  />
                </Box>
              </Stack>
            </Stack>
          ))}
        </CardContent>
      )}
    </Card>
  );
}