import * as React from 'react';
import { useEffect, useContext } from "react";

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Button, Chip, Icon, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { UserContext, SkillsContext } from "../../utils/context.js";
import { UserCareerStepSkill } from '../../services/api/schemas/UserCareerStepSkill';
import { getUserRatings } from '../../services/DashboardAPI';
import { getSkillRating } from '../../services/api/SkillRating';
import { SkillRating } from '../../services/api/schemas/SkillRating';

export default function ExpectationsSkills() {
  const [user] = useContext(UserContext);
  const [skills] = useContext(SkillsContext);
  
  const [strengthsopps, setUserRatings] = React.useState<StrengthOpportunityInfo[]>([]);
  const [skill_ratings, setSkillRatings] = React.useState<SkillRating[]>([]);


  const getUserSkillRating = (career_step_skill_id: number) => {
    const foundSkill = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.career_step_skill_id === career_step_skill_id
    );
    return foundSkill ? foundSkill.average_rating : 0; 
  };

  const fetchUserRatings = async (user_id: number) => {
    await getUserRatings(user_id).then((results) => {
      const all_results = results as StrengthOpportunityInfo[] || [];
      setUserRatings(all_results);
    }).catch((err) => {
      console.error("Failed to fetch user ratings: ", err);
    });
  };
  
  const fetchSkillRating = async (skill_id: number) => {
    await getSkillRating(skill_id).then((results) => {
      results.sort((one, two) => (one.skill_rating_level > two.skill_rating_level ? -1 : 1));
      setSkillRatings(results);
    }).catch((err) => {
      console.error("Failed to fetch skill ratings: ", err);
    });
  };

  useEffect(() => {
      try {
        if (user && skills){
          fetchUserRatings(user.user_id);
        }      
      } catch (err) {
        console.error("Failed to retrieve skills expectations. ", err);
      }
    }, [user, skills]);
    
    // POPUP ratings
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      try {
        const {value} = event.currentTarget;
        var selected_skill_id: number = +value;
        fetchSkillRating(selected_skill_id);
        } catch (err) {
        console.error("Failed to retrieve skills ID from 'i' button (for rating). ", err);
      }
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    
  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      {skills && skills.length > 0 && (
        <CardContent>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
          <HandymanOutlinedIcon /> Skills expected
          </Typography>
          {skills.map((skill:UserCareerStepSkill) => (
            <Stack
              key={skill.career_step_skill_id}
              direction="row"
              sx={{ alignItems: 'center', gap: 2, pb: 2 }}
            >
              {skill.must_have ? (
                <Tooltip title="Must have" arrow>
                  <Icon sx={{ width: 24, height: 24 }}><StarOutlineOutlinedIcon /></Icon>
                </Tooltip>
              ):(
                <Icon sx={{ width: 24, height: 24 }}></Icon>
              )}
              <Stack sx={{ gap: 1, flexGrow: 1 }}>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    {skill.skill.skill_name} 
                    <Button aria-describedby={id} value={skill.skill_id} size="small" onClick={handleClick}>
                      <InfoOutlinedIcon />
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      {skill_ratings && skill_ratings.length > 0 && (
                        <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/*<TableCell align="center" size="small"></TableCell>*/}
                              <TableCell align="center" size="small"><b>Level</b></TableCell>
                              <TableCell align="left"><b>Definition</b></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {skill_ratings.map((skill_rating) => (
                              <TableRow key={skill_rating.skill_rating_level}>
                                <TableCell align="center">
                                  {skill_rating.skill_rating_level*20}%
                                </TableCell>
                                <TableCell align="left">
                                  {skill_rating.skill_rating_definition}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Popover>
                  </Typography>
                  {getUserSkillRating(skill.career_step_skill_id) > 0 ?
                    (<Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      rated {(getUserSkillRating(skill.career_step_skill_id)/5*100).toFixed(0)}%, 
                      expected {(skill.expected_rating/5*100).toFixed(0)}%
                    </Typography>)
                    :
                    (<Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      not rated yet, expected {(skill.expected_rating/5*100).toFixed(0)}%
                    </Typography>)
                  }
                </Stack>
                <Box sx={{ width: skill.expected_rating/5 }}>
                  <LinearProgress
                    variant="buffer"
                    aria-label="value"
                    value={getUserSkillRating(skill.career_step_skill_id)/skill.expected_rating*100}
                    valueBuffer={getUserSkillRating(skill.career_step_skill_id)/skill.expected_rating*100}
                  />
                </Box>
              </Stack>
            </Stack>
          ))}
        </CardContent>
      )}
    </Card>
  );
}