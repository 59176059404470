import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow, Typography
} from "@mui/material";
import {AutoAwesome, ThumbDownAltOutlined, ThumbUpAltOutlined} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {StepSuggestionCard} from "./StepSuggestionCard";

interface ActionPlanSuggestionCardProps {
    suggestionInfo: ActionItemSuggestionInfo;
    onAcceptActionItemSuggestion: (suggestion: ActionItemSuggestionInfo, isAccepted: boolean) => void;
}

export const ActionPlanSuggestionCard = ({
                                             suggestionInfo,
                                             onAcceptActionItemSuggestion,
                                         }: ActionPlanSuggestionCardProps) => {

    function handleRejectSuggestion(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onAcceptActionItemSuggestion(suggestionInfo, false);
    }

    function handleAcceptSuggestion(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onAcceptActionItemSuggestion(suggestionInfo, true);
    }

    function handleAcceptSuggestionStep(suggestionStep: ActionItemStepSuggestionInfo, isAccepted: boolean) {
        onAcceptActionItemSuggestion(suggestionInfo, isAccepted);
    }


    const suggestionContent = (
        <Table sx={{tableLayout: "auto"}}>
            <TableBody>
                <TableRow sx={{
                    // Removes the bottom border of the last table row
                    "&:last-child td, &:last-child th": {
                        borderBottom: "none",
                    },
                    display: "flex",
                }}>
                    <TableCell size="small" width="5%" sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <AutoAwesome
                            sx={{color: "grey"}}
                        />
                    </TableCell>
                    <TableCell align="left" sx={{
                        color: "grey",
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                    }}>
                        <Typography variant="h5" gutterBottom sx={{margin: 0}}>
                            {suggestionInfo.user_action_plan_suggestion_goal}
                        </Typography>
                    </TableCell>
                    {/*Placeholder cell to fill in the space and push the next cell to the right*/}
                    <TableCell sx={{flexGrow: 1}}/>

                    <TableCell align={"right"}>
                        <Stack direction={"row"} justifyContent="flex-end">
                            <IconButton size="medium" color={"primary"}
                                        onClick={handleAcceptSuggestion} sx={{border: 0}}>
                                <ThumbUpAltOutlined fontSize="small" />
                            </IconButton>
                            <IconButton size="medium" color={"primary"}
                                        onClick={handleRejectSuggestion} sx={{border: 0}}>
                                <ThumbDownAltOutlined fontSize="small"/>
                            </IconButton>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const suggestionSteps = (
        <Stack spacing={2}>
            <Table>
                <TableBody>
                    {(suggestionInfo.user_action_plan_step_suggestions || []).map((itemStep) => (
                        <StepSuggestionCard
                            stepSuggestionInfo={itemStep}
                            onAcceptSuggestionStep={handleAcceptSuggestionStep}
                            showAcceptRejectButtons={false}
                            key={itemStep.user_action_plan_step_suggestion_id}
                        />
                    ))}
                </TableBody>
            </Table>
        </Stack>
    );

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} id="panel-header">
                {suggestionContent}
            </AccordionSummary>
            <AccordionDetails>
                {suggestionSteps}
            </AccordionDetails>
        </Accordion>)
};
