import React, {useContext, useEffect, useState} from "react";
import {Box, Button, FormControlLabel, Grid2,} from "@mui/material";

import Switch from "@mui/material/Switch";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import dayjs from "dayjs";

import {createTeamGoals, 
        deleteTeamGoal, 
        getTeamGoals, 
        GOAL_STATUS,
        updateTeamGoal, 
        updateTeamGoalAggregate, 
        updateTeamGoalsKpi,} from "../../services/TeamGoalsPublicAPI";

import { getVision } from "../../services/api/Visions";

import {UserContext} from "../../utils/context";

import {PageHeader} from "../_PageHeader";

//import {sortTeamGoals, sortTeamGoalKpis,} from "../../utils/TeamGoalsUtils";

import {TeamGoalCard} from "./TeamGoalCard";
import { TeamGoalCardEdit } from "./TeamGoalCardEdit";

interface ListItemContainerProps {
    itemInfo: TeamGoalInfo,
    onSave: (updatedItem: TeamGoalInfo) => void,
    onDelete: (item: TeamGoalInfo) => void,
    onTeamGoalCompleted: (item: TeamGoalInfo, isCompleted: boolean) => void,
    onKpiCompleted: (kpi: TeamGoalKpiInfo, isCompleted: boolean) => void,
    showActiveOnly: boolean,
    viewLevelDetails: boolean
}


export const TeamGoalsPage = () => {
    const [user] = useContext(UserContext);
    const [goals, setGoals] = useState<TeamGoalInfo[]>([]);
    const [createTeamGoal, setCreateTeamGoal] = useState(false);
    const [showActiveOnly, setShowActiveOnly] = useState(true);
    const [viewLevelDetails, setViewLevelDetails] = useState(false);
    const [strategyId, setStrategyId] = useState<number>(0);

    const ListItemContainer = ({
        itemInfo,
        onSave,
        onDelete,
        onTeamGoalCompleted,
        onKpiCompleted: onKpiCompleted,
        showActiveOnly,
        viewLevelDetails
    }: ListItemContainerProps) => {
        const [isEditing, setIsEditing] = useState(false);
        const handleEdit = () => setIsEditing(true);
        const handleCancelEdit = () => setIsEditing(false);

        function handleSave(updatedItem: TeamGoalInfo) {
        setIsEditing(false);
        onSave(updatedItem);
        }

        return isEditing ? (
            <TeamGoalCardEdit goalEdit={itemInfo} userId={user.user_id} onCancel={handleCancelEdit}
                    onSave={handleSave}/>
            ) : (
            <TeamGoalCard
            goalInfo={itemInfo}
            onDelete={onDelete}
            onEdit={handleEdit}
            onTeamGoalCompleted={onTeamGoalCompleted}
            onKpiCompleted={onKpiCompleted}
            showActiveOnly={showActiveOnly}
            viewLevelDetails={viewLevelDetails}
            />
        );
    };

    useEffect(() => {
        try {
        if (user && user.org_id) {
            fetchGoals(user.org_id, showActiveOnly);
            fetchVisionAndStrategy(user.org_id);
        }
        } catch (err) {
        console.error("Failed to retrieve goals. ", err);
        }
    }, [user]);

    const fetchGoals = async (org_id: number, viewActiveOnly: boolean) => {
        const filterStatus = viewActiveOnly ? GOAL_STATUS.ACTIVE : "";

        return await getTeamGoals(org_id, filterStatus).then((value) => {
        setGoals(value);
        });
    };

    const fetchVisionAndStrategy = async (org_id: number) => {
        return await getVision(org_id).then((value) => {
            if (value && value.strategies && value.strategies.length > 0) {
                setStrategyId(value.strategies[0].strategy_id);
            }
        });
    };

    function handleKpiCompleted(kpi: TeamGoalKpiInfo, isCompleted: boolean) {
        kpi.kpi_achieved_date = isCompleted ? dayjs().format("YYYY-MM-DD") : null;
        kpi.kpi_achieved_user_id = user.user_id;
        updateTeamGoalsKpi(kpi).then(() => {
            fetchGoals(user.org_id, showActiveOnly);
        }).catch((e) => {
            console.error("Failed to update team goal kpi: " + e);
        });
    }

    function handleTeamGoalCompleted(goal: TeamGoalInfo, isCompleted: boolean) {
        goal.goal_achieved_date = isCompleted ? dayjs().format("YYYY-MM-DD") : null;
        goal.goal_achieved_user_id = user.user_id;
        goal.goal_status = GOAL_STATUS.ACHIEVED;
        updateTeamGoal({...goal}).then(() => {
            fetchGoals(user.org_id, showActiveOnly);
        }).catch((e) => {
            console.error("Failed to update team goal: " + e);
            // TODO: show a UI error
        });
    }

    function handleDeleteTeamGoal(goal: TeamGoalInfo) {
        // TODO: add prompt "Are you sure you want to delete?"
        deleteTeamGoal(goal.goal_id).then(() => {
            fetchGoals(user.org_id, showActiveOnly);
        }).catch((e) => {
            console.error("Failed to delete team goal: " + e, " goal: " + goal);
            // TODO: handle failure
        });
    }

    function handleSave(updatedItem: TeamGoalInfo) {
        updateTeamGoalAggregate(updatedItem)
            .then(() => {
                fetchGoals(user.org_id, showActiveOnly);
            })
            .catch((e) => {
                // TODO: handle failure
            });
    }

    const renderTeamGoals = () =>
        goals.map((value) => {
            const visible = !(showActiveOnly && value.goal_achieved_date);
            return visible && (
                <ListItemContainer
                    itemInfo={value}
                    // TODO: replace Date.now with a unique id in the mapping array
                    key={`goal-${value.goal_id}-${Date.now()}`}
                    onSave={handleSave}
                    onDelete={handleDeleteTeamGoal}
                    onTeamGoalCompleted={handleTeamGoalCompleted}
                    onKpiCompleted={handleKpiCompleted}
                    showActiveOnly={showActiveOnly}
                    viewLevelDetails={viewLevelDetails}
                />
            );
        });


    function handleChangeDetailsView() {
        setViewLevelDetails(!viewLevelDetails);
    }

    function handleNewTeamGoal() {
        setCreateTeamGoal(true);
    }

    const handleActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowActiveOnly(event.target.checked);
        fetchGoals(user.org_id, event.target.checked);
    };

    const renderTopToolBar = () => (
        <Grid2 container sx={{my: 2}} justifyContent="flex-end">
            {goals && goals.length > 0 && (
                <>
                    <FormControlLabel
                        label="active only"
                        control={<Switch id="teamgoals-viewStatusChange-switch" defaultChecked onChange={handleActiveOnlyChange}/>}
                    />
                    {/*TODO: do not show Zoom In / Zoom Out buttons for now (see style, visibility)*/}
                    {viewLevelDetails ? (
                        <Button color="info" onClick={handleChangeDetailsView}>
                            <ZoomOutOutlinedIcon/>
                        </Button>
                    ) : (
                        <Button color="info" onClick={handleChangeDetailsView}>
                            <ZoomInOutlinedIcon/>
                        </Button>
                    )}
                </>
            )}
            <Button variant="contained" onClick={handleNewTeamGoal}>+ New Goal</Button>
        </Grid2>
    );

    const emptyItem: TeamGoalInfo = {
        goal_id: 0,
        strategy_id: strategyId,
        goal_description: "",
        goal_kpis: [],
        goal_status: GOAL_STATUS.PENDING,
        goal_target_date: null,
        goal_created_date: dayjs().format("YYYY-MM-DD"),
        goal_created_user_id: user?.user_id || 0,
        goal_achieved_date: null,
        goal_achieved_user_id: null,
    };

    function handleCreateTeamGoal(newTeamGoal: TeamGoalInfo) {
        createTeamGoals(strategyId, newTeamGoal).then((response) => {
            const newTeamGoal = response as TeamGoalInfo;
            console.log("Created new team goal: " + JSON.stringify(newTeamGoal));
            // Add newly created ActionPlanInfo item to the mapping
            goals.push(newTeamGoal);
            setCreateTeamGoal(false);
        }).catch((e) => {
            console.error("Failed to create new team goal: " + e);
            // TODO: handle error
        });
    }

    return (
        <Box sx={{width: "100%", maxWidth: {sm: "100%", md: "1700px"}}}>
            <Grid2>
                <PageHeader
                    title="Team goals" 
                    subtitle="This is what your team is actively working on.
                    Focus on the most important goal and 1-3 indicators of success.
                    Add more goals as you get close to completion: your impact on these goals will be part of your feedback."
                />
            </Grid2>

            <Grid2>
                {
                    (createTeamGoal) ? (
                        <>
                            <TeamGoalCardEdit goalEdit={emptyItem} userId={user.user_id} onCancel={() => setCreateTeamGoal(false)}
                                                    onSave={handleCreateTeamGoal}/>
                        </>
                    ) : (
                        <>
                            {renderTopToolBar()}
                            {goals && renderTeamGoals()}
                        </>)
                }
            </Grid2>
        </Box>
    );
};