const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

type UpdateActionPlanStep = {
    user_action_plan_step_id: number | null;
    user_action_plan_step_description: string;
    user_action_plan_step_measurement: string;
    user_action_plan_step_target_date: string;
    user_action_plan_step_date_completed: string | null;
};

type UpdateActionPlan = {
    user_action_plan_id: number;
    user_action_plan_priority: number;
    user_action_plan_goal: string;
    user_action_plan_business_value: string;
    user_action_plan_date_completed: string | null;
    user_action_plan_steps: Array<UpdateActionPlanStep>;
};

export const getActionPlan = async (userId: number) => {
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans?` +
            new URLSearchParams({
                user_id: userId.toString(),
            }).toString(),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const createActionPlan = async (
    userId: number,
    actionItem: ActionItemInfo
) => {
    const actionPlanBody = {
        ...actionItem,
        user_action_plan_steps: actionItem.user_action_plan_steps?.map(step => ({
            ...step,
            // Drop the negative step IDs for new steps (the backend will assign new IDs)
            user_action_plan_step_id: (step.user_action_plan_step_id == null || step.user_action_plan_step_id < 0) ?
                null :
                step.user_action_plan_step_id,
        })),
        user_id: userId
    };

    const response = await fetch(`${PUBLIC_API_URL}/user_action_plans`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(actionPlanBody),
    });
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`createActionPlan request failed with status ${response.status}: ${response.statusText}`);
    }

    return response.json();
};

export const updateActionPlan = async (actionPlan: ActionItemInfo) => {
    try {
        const actionPlanBody: UpdateActionPlan = {
            user_action_plan_id: actionPlan.user_action_plan_id,
            user_action_plan_priority: Number(actionPlan.user_action_plan_priority),
            user_action_plan_goal: actionPlan.user_action_plan_goal,
            user_action_plan_business_value: actionPlan.user_action_plan_business_value,
            user_action_plan_date_completed: actionPlan.user_action_plan_date_completed || null,
            user_action_plan_steps: actionPlan.user_action_plan_steps || [],
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/plan_id?` +
            new URLSearchParams({
                plan_id: actionPlan.user_action_plan_id.toString(),
            }).toString(),
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...actionPlanBody}),
            }
        );
        return response.status;
    } catch (err) {
        console.error(err);
    }
};

export const updateActionPlanAggregate = async (actionPlan: ActionItemInfo) => {
    try {
        const actionPlanBody: UpdateActionPlan = {
            user_action_plan_id: actionPlan.user_action_plan_id,
            user_action_plan_priority: Number(actionPlan.user_action_plan_priority),
            user_action_plan_goal: actionPlan.user_action_plan_goal,
            user_action_plan_business_value: actionPlan.user_action_plan_business_value,
            user_action_plan_date_completed: actionPlan.user_action_plan_date_completed || null,
            user_action_plan_steps: (actionPlan.user_action_plan_steps || []).map((step) => {
                return {
                    // Drop the negative step IDs for new steps (the backend will assign new IDs)
                    //user_action_plan_step_id: step.user_action_plan_step_id,
                    user_action_plan_step_id: (step.user_action_plan_step_id == null || step.user_action_plan_step_id < 0) ?
                    null :
                    step.user_action_plan_step_id,
                    user_action_plan_step_description: step.user_action_plan_step_description,
                    user_action_plan_step_measurement: step.user_action_plan_step_measurement,
                    user_action_plan_step_target_date: step.user_action_plan_step_target_date,
                    user_action_plan_step_date_completed: step.user_action_plan_step_date_completed || null,
                };
            }),
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/${actionPlan.user_action_plan_id}`,
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...actionPlanBody}),
            }
        );
        return response.status;
    } catch (err) {
        console.error(err);
    }
};

export const createActionPlanStep = async (
    planId: number,
    description: string,
    measurement: string,
    targetDate: string,
    dateCompleted: string | null
) => {
    try {
        const stepBody = {
            user_action_plan_step_description: description,
            user_action_plan_step_measurement: measurement,
            user_action_plan_step_target_date: targetDate,
            user_action_plan_step_date_completed:
                dateCompleted === "" ? null : dateCompleted,
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/plan_id/steps?` +
            new URLSearchParams({
                plan_id: planId.toString(),
            }).toString(),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(stepBody),
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const getActionPlanStep = async (planId: number, stepId: number) => {
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/plan_id/steps/step_id?` +
            new URLSearchParams({
                plan_id: planId.toString(),
                step_id: stepId.toString(),
            }).toString(),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const updateActionPlanStep = async (step: ActionItemStepInfo) => {
    const stepBody = {...step};
    const response = await fetch(
        `${PUBLIC_API_URL}/user_action_plans/plan_id/steps/step_id?` +
        new URLSearchParams({
            plan_id: step.user_action_plan_id.toString(),
            step_id: step.user_action_plan_step_id!.toString(),
        }).toString(),
        {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(stepBody),
        }
    );
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`updateActionPlanStep request failed with status ${response.status}: ${response.statusText}`);
    }

    return response.json();
};

export const deleteActionPlan = async (planId: number) => {
    const response = await fetch(`${PUBLIC_API_URL}/user_action_plans/plan_id?` +
        new URLSearchParams({
            plan_id: planId.toString(),
        }).toString(),
        {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        }
    );
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`deleteActionPlan request failed with status ${response.status}: ${response.statusText}`);
    }

    return response.json();
};