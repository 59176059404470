import * as React from 'react';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Grid2';

import { PageHeader } from '../_PageHeader';

import ExpectationsSkills from './ExpectationsSkills';
import ExpectationsCompetencies from './ExpectationsCompetencies';
import UserCareerPath from './UserCareerPath';


export default function UserExpectationsProgressPage() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <PageHeader
        title="My career"
        subtitle="Review expectations for your current (and future) level"
      />
      {/*
      <Grid2
        sx={{ mb: (theme) => theme.spacing(2) }}
        >
        <UserCareerPath />
      </Grid2>
      */}
      <Grid2
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <ExpectationsSkills />
        <ExpectationsCompetencies />
      </Grid2>
      <Grid2
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
      </Grid2>
      </Box>
  );
}