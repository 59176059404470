import * as React from 'react';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Grid2';

import { PathConstants } from '../../utils/nav';

import { PageHeader } from '../_PageHeader';
import HighlightedCard from '../_HighlightedCard';
import StatCardX, { StatCardXProps } from './StatCardX';

import ChartStrengths from './ChartStrengths';
import ChartOpportunities from './ChartOpportunities';
import MainAlert from '../_spa/MainAlert';


const engagementDataX: StatCardXProps[] = [
  { 
    title: 'Feedback received',
    trend_type: 'USER_FEEDBACK_RECEIVED_FREQUENCY',
  }, { 
    title: 'Feedback given',
    trend_type: 'USER_FEEDBACK_PROVIDED_FREQUENCY',
  }, 
];
const ratingDataX: StatCardXProps[] = [
  { 
    title: 'Impact percieved',
    trend_type: 'USER_FEEDBACK_RECEIVED_IMPACT',
  }, { 
    title: 'Action plan success',
    trend_type: 'USER_FEEDBACK_RECEIVED_ACTION_ITEM',
  }, { 
    title: 'Skills',
    trend_type: 'USER_FEEDBACK_RECEIVED_SKILLS',
  }, { 
    title: 'Competencies',
    trend_type: 'USER_FEEDBACK_RECEIVED_COMPETENCIES',
  },
];

export default function MainGrid() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <MainAlert />
        <PageHeader
          title="Strengths and Opportunities"
          subtitle=""
        />
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          <ChartStrengths />
          <ChartOpportunities />
        </Grid2>
        <PageHeader
          title="Feedback trends"
          subtitle=""
        />
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {ratingDataX.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCardX {...card} />
            </Grid2>
          ))}
        </Grid2>
        <PageHeader
          title="Feedback frequency"
          subtitle=""
        />
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {engagementDataX.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCardX {...card} />
            </Grid2>
          ))}
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <HighlightedCard 
              title="Explore your feedback" 
              details="Uncover what generated these charts." 
              cta="Go to feedback" 
              route={PathConstants.FEEDBACK} />
          </Grid2>
        </Grid2>
      </Box>
  );
}