import dayjs from "dayjs";

const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

export enum GOAL_STATUS {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  ACHIEVED = "ACHIEVED",
  DELETED = "DELETED"
};


type UpdateTeamGoalKpi = {
  kpi_name: string;
  kpi_measure_definition: string | null;
  kpi_target_date: string | null;
  kpi_target_value: number | null;
  kpi_weight: number | null;
  kpi_created_date: string;
  kpi_created_user_id: number;
  kpi_achieved_date: string | null;
  kpi_achieved_user_id: number | null;
  kpi_status: string;
  kpi_id: number | null;
};

type UpdateTeamGoal = {
  strategy_id: number;
  goal_description: string;
  goal_target_date: string | null;
  goal_created_date: string;
  goal_created_user_id: number;
  goal_achieved_date: string | null;
  goal_achieved_user_id: number | null;
  goal_status: string;
  goal_kpis?: Array<UpdateTeamGoalKpi>;
};

type CreateTeamGoalKpi = {
  kpi_name: string;
  kpi_measure_definition: string | null;
  kpi_target_date: string | null;
  kpi_target_value: number | null;
  kpi_weight: number | null;
  kpi_created_date: string;
  kpi_created_user_id: number;
  kpi_achieved_date: string | null;
  kpi_achieved_user_id: number | null;
  kpi_status: string;
};

type CreateTeamGoal = {
  strategy_id: number;
  goal_description: string;
  goal_target_date: string | null;
  goal_created_date: string;
  goal_created_user_id: number;
  goal_achieved_date: string | null;
  goal_achieved_user_id: number | null;
  goal_status: string;
  goal_kpis?: Array<CreateTeamGoalKpi>;
};

export const getTeamGoals = async (OrgId: number, GoalStatus?: string) => {
  try {
    var params = null;
    if (GoalStatus) {
       params = {org_id: OrgId.toString(), goal_status: GoalStatus};
    }
    else {
      params = {org_id: OrgId.toString()};
    }

    const response = await fetch(
      `${PUBLIC_API_URL}/goals?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const createTeamGoals = async (
    strategyId: number,
    teamGoal: TeamGoalInfo
) => {
    const teamGoalBody: CreateTeamGoal = {
      strategy_id: strategyId,
      goal_description: teamGoal.goal_description,
      goal_target_date: teamGoal.goal_target_date,
      goal_created_date: teamGoal.goal_created_date,
      goal_created_user_id: teamGoal.goal_created_user_id,
      goal_achieved_date: teamGoal.goal_achieved_date,
      goal_achieved_user_id: teamGoal.goal_achieved_user_id,
      goal_status: teamGoal.goal_status,
      goal_kpis: (teamGoal.goal_kpis || []).map((kpi) => {
        return {
            kpi_name: kpi.kpi_name,
            kpi_measure_definition: kpi.kpi_measure_definition,
            kpi_target_date: kpi.kpi_target_date,
            kpi_target_value: kpi.kpi_target_value,
            kpi_weight: kpi.kpi_weight,
            kpi_created_date: kpi.kpi_created_date,
            kpi_created_user_id: kpi.kpi_created_user_id,
            kpi_achieved_date: kpi.kpi_achieved_date,
            kpi_achieved_user_id: kpi.kpi_achieved_user_id,
            kpi_status: kpi.kpi_status,
        };
      }),
    };
    const response = await fetch(`${PUBLIC_API_URL}/goals`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(teamGoalBody),
    });
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`createTeamGoal request failed with status ${response.status}: ${response.statusText}`);
    }

    return response.json();
};


export const updateTeamGoal = async (teamGoal: TeamGoalInfo) => {
    try {
        const teamGoalBody: CreateTeamGoal = {
          strategy_id: teamGoal.strategy_id,
          goal_description: teamGoal.goal_description,
          goal_target_date: teamGoal.goal_target_date,
          goal_created_date: teamGoal.goal_created_date,
          goal_created_user_id: teamGoal.goal_created_user_id,
          goal_achieved_date: teamGoal.goal_achieved_date,
          goal_achieved_user_id: teamGoal.goal_achieved_user_id,
          goal_status: teamGoal.goal_status,
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/goals/goal_id?` +
            new URLSearchParams({
              goal_id: teamGoal.goal_id.toString(),
            }).toString(),
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...teamGoalBody}),
            }
        );
        return response.status;
    } catch (err) {
        console.error(err);
    }
};

export const updateTeamGoalAggregate = async (teamGoal: TeamGoalInfo) => {
    try {
        const teamGoalBody: UpdateTeamGoal = {
          strategy_id: teamGoal.strategy_id,
          goal_description: teamGoal.goal_description,
          goal_target_date: teamGoal.goal_target_date,
          goal_created_date: teamGoal.goal_created_date,
          goal_created_user_id: teamGoal.goal_created_user_id,
          goal_achieved_date: teamGoal.goal_achieved_date || null,
          goal_achieved_user_id: teamGoal.goal_achieved_user_id || null,
          goal_status: teamGoal.goal_status,
          goal_kpis: (teamGoal.goal_kpis || []).map((kpi) => {
            return {
                kpi_name: kpi.kpi_name,
                kpi_measure_definition: kpi.kpi_measure_definition,
                kpi_target_date: kpi.kpi_target_date,
                kpi_target_value: kpi.kpi_target_value,
                kpi_weight: kpi.kpi_weight,
                kpi_created_date: kpi.kpi_created_date,
                kpi_created_user_id: kpi.kpi_created_user_id,
                kpi_achieved_date: kpi.kpi_achieved_date || null,
                kpi_achieved_user_id: kpi.kpi_achieved_user_id || null,
                kpi_status: kpi.kpi_status,
                // Drop the negative step IDs for new kpi (the backend will assign new IDs)
                kpi_id: (kpi.kpi_id == null || kpi.kpi_id < 0) ? null : kpi.kpi_id,
            };
          }),
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/goals/${teamGoal.goal_id}`,
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...teamGoalBody}),
            }
        );
        return response.status;
    } catch (err) {
        console.error(err);
    }
};

export const createTeamGoalsKpi = async (
  goalId: number,
  kpiName: string,
  kpiMeasurement: string | null,
  kpiTargetDate: string | null,
  kpiTargetValue: number | null,
  kpiWeight: number | null,
  kpiCreatedUserId: number
) => {
  try {
    const kpiBody = {
      kpi_name: kpiName,
      kpi_measure_definition: kpiMeasurement,
      kpi_target_date: kpiTargetDate,
      kpi_target_value: kpiTargetValue,
      kpi_weight: kpiWeight,
      kpi_created_date: dayjs().format("YYYY-mm-dd"),
      kpi_created_user_id: kpiCreatedUserId,
      kpi_achieved_date: null,
      kpi_achieved_user_id: null,
      kpi_status: "ACTIVE",
    };
    const response = await fetch(
      `${PUBLIC_API_URL}/goals/goal_id/kpis?` +
        new URLSearchParams({
          goal_id: goalId.toString(),
        }).toString(),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(kpiBody),
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getTeamGoalsKpi = async (goalId: number, kpiId: number) => {
  try {
    const response = await fetch(
      `${PUBLIC_API_URL}/goals/goal_id/kpis/kpi_id?` +
        new URLSearchParams({
          goal_id: goalId.toString(),
          kpi_id: kpiId.toString(),
        }).toString(),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const updateTeamGoalsKpi = async (kpi: TeamGoalKpiInfo) => {
    //const kpiBody = {...kpi};
    const kpiBody = {
      kpi_name : kpi.kpi_name,
      kpi_measure_definition : kpi.kpi_measure_definition,
      kpi_target_date : kpi.kpi_target_date,
      kpi_target_value : kpi.kpi_target_value,
      kpi_weight : kpi.kpi_weight,
      kpi_created_date : kpi.kpi_created_date,
      kpi_created_user_id : kpi.kpi_created_user_id,
      kpi_achieved_date : kpi.kpi_achieved_date,
      kpi_achieved_user_id : kpi.kpi_achieved_user_id,
      kpi_status : kpi.kpi_status,
    };
    const response = await fetch(
        `${PUBLIC_API_URL}/goals/goal_id/kpis/kpi_id?` +
        new URLSearchParams({
            goal_id: kpi.goal_id.toString(),
            kpi_id: kpi.kpi_id!.toString(),
        }).toString(),
        {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(kpiBody),
        }
    );
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`updateTeamGoalsKpi request failed with status ${response.status}: ${response.statusText}`);
    }

    return response.json();
};

export const deleteTeamGoal = async (goalId: number) => {
    const response = await fetch(`${PUBLIC_API_URL}/goals/goal_id?` +
        new URLSearchParams({
          goal_id: goalId.toString(),
        }).toString(),
        {
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        }
    );
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`deleteTeamGoal request failed with status ${response.status}: ${response.statusText}`);
    }

    return response.json();
};