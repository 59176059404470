import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Link, Stack } from "@mui/material";

import { UserContext } from "../../utils/context.js";

import dayjs from "dayjs";

import { getUrgentNotifications, updateUrgentNotifications } from "../../services/NotificationsAPI";

export default function MainAlert() {
  const [user] = useContext(UserContext);
  const [notifications, setNotifications] = React.useState<NotificationInfo[]>([]);
  const navigate = useNavigate();


  React.useEffect(() => {
    fetchUrgentNotifications(user.user_id);
  }, [user]);

  const fetchUrgentNotifications = async (user_id: number) => {
    var results_not_dismissed: NotificationInfo[] = [];

    getUrgentNotifications(user_id).then((results) => {
      const all_results = results as NotificationInfo[] || [];
      all_results.forEach((element) => {
        if (!element.user_notification_date_dismissed) results_not_dismissed.push(element);
        setNotifications(results_not_dismissed);
      });
    }).catch((err) => {
      console.error("Failed to fetch urgent notifications: ", err);
    });
  };

  const handleAlertDismiss = async (notification: NotificationInfo) => {
    try {
      notification.user_notification_date_dismissed = dayjs().format("YYYY-MM-DD");
      await updateUrgentNotifications(notification);
      if (user) {
        fetchUrgentNotifications(user?.user_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ctaClick = (route: string) => {
      navigate(route);
  };

  
  return (
    <Stack sx={{ width: '100%' }} spacing={1}>    
      {notifications && notifications.length > 0 &&
        notifications.map((notification) => (
          <Alert 
            key={notification.user_notification_id} 
            variant="outlined" 
            severity={
              notification.user_notification_severity === 'error' ||
              notification.user_notification_severity === 'warning' ||
              notification.user_notification_severity === 'info' ||
              notification.user_notification_severity === 'success'
                ? notification.user_notification_severity
                : 'info'
            }
            action={
              <Button color="inherit" size="small" onClick={() => {handleAlertDismiss(notification);}}>
                DISMISS
              </Button>
              }
            >{notification.user_notification_link ? 
                <Link onClick={() => {ctaClick(notification.user_notification_link!);}}>
                  {notification.user_notification_details}
                </Link>
                :
                  notification.user_notification_details
              }
            </Alert>
        ))
    }
  </Stack>
);
}
