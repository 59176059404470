import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { firebaseConfig } from "./config";
import { UserAuthInfo } from "../UserAuthInfo";

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default class FirebaseAuth {
  getIdToken = async () => {
    let currentUser = auth.currentUser;

    if (!currentUser) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      currentUser = auth.currentUser;
    }

    if (currentUser) {
      return await currentUser.getIdToken();
    }
    return null;
  };

  getUserInfo = () => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      return currentUser as UserAuthInfo;
    }
    return null;
  };

  signOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };
}
