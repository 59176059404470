import { get } from "./helpers";
import { UserCareerStepCompetency } from "./schemas/UserCareerStepCompetency";

export  const getUserCareerStepCompetencies = async (level_id: number): Promise<UserCareerStepCompetency[]> => {
  try {
    const params = {level_id: level_id.toString()};
    return await get<UserCareerStepCompetency[]>("/career_step_competencies/level_id?" + new URLSearchParams(params).toString());
  } catch (error) {
    console.error("Failed to retrieve user career step competencies. ", error);
    return [];
  }
};
