const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;


export const getUrgentNotifications = async (UserId: number) => {
    const params = {user_id: UserId.toString()};

    const response = await fetch(
      `${PUBLIC_API_URL}/user_notifications/user_id?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (response.status !== 200) {
        // Throw an error if the status is not 200
        throw new Error(`getUrgentNotifications request failed with status ${response.status}: ${response.statusText}`);
    }
    return response.json();
};

export const updateUrgentNotifications = async (notification: NotificationInfo) => {
  const params = {user_notification_id: notification.user_notification_id.toString()};

  try {
      const notificationBody: NotificationInfo = {
        user_notification_id: Number(notification.user_notification_id),
        user_id: Number(notification.user_id),
        user_notification_date_created: notification.user_notification_date_created,
        user_notification_date_dismissed: notification.user_notification_date_dismissed || null,
        user_notification_details: notification.user_notification_details || null,
        user_notification_link: notification.user_notification_link || null,
        user_notification_severity: notification.user_notification_severity || null,
      };
      const response = await fetch(
          `${PUBLIC_API_URL}/user_notifications/user_notification_id?` +
          new URLSearchParams(params).toString(),
          {
              method: "PUT",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({...notificationBody}),
          }
      );
      return response.status;
  } catch (err) {
      console.error(err);
  }
};
