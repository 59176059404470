import dayjs from "dayjs";

export type ChipColor = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";

export function colorFromDate(target_date: string, completed_date: string | null): ChipColor {
    if (completed_date) {
        return "default"
    }
    else {
        const diffInDays = dayjs(target_date).startOf('day').diff(dayjs().startOf('day'), 'day');

        if (diffInDays >= 2) {
            return "success"
        } else if (diffInDays < 0) {
            return "error"
        } else {
            return "warning"
        }
    }
}
