import * as React from 'react';
import { useContext } from "react";

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";

import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
import Filter2OutlinedIcon from '@mui/icons-material/Filter2Outlined';
import Filter3OutlinedIcon from '@mui/icons-material/Filter3Outlined';

import { UserContext, SkillsContext, CompetenciesContext } from "../../utils/context.js";
import { UserCareerStepSkill } from '../../services/api/schemas/UserCareerStepSkill';
import { UserCareerStepCompetency } from '../../services/api/schemas/UserCareerStepCompetency';

import { getUserStrengths } from "../../services/DashboardAPI";
import { Tooltip } from '@mui/material';

export default function ChartStrengths() {
  const [user] = useContext(UserContext);
  const [skills] = useContext(SkillsContext);
  const [competencies] = useContext(CompetenciesContext);
  const [strengthsopps, setUserStrengths] = React.useState<StrengthOpportunityInfo[]>([]);

  const getDisplayNameSkill = (career_step_skill_id: number) => {
    const foundSkill = skills.find(
      (skill_details:UserCareerStepSkill) => skill_details.career_step_skill_id === career_step_skill_id
    );
    return foundSkill ? foundSkill.skill.skill_name : ''; 
  };
  const getDisplayNameCompetency = (career_step_competency_id: number) => {
    const foundCompetency = competencies.find(
      (competency_details:UserCareerStepCompetency) => competency_details.career_step_competency_id === career_step_competency_id
    );
    return foundCompetency ? foundCompetency.competency.competency_name : ''; 
  };

  const fetchUserStrengths = async (user_id: number) => {
    var results_with_name: StrengthOpportunityInfo[] = [];

    await getUserStrengths(user_id).then((results) => {
      const all_results = results as StrengthOpportunityInfo[] || [];
      all_results.forEach((element) => {
        if (element.career_step_competency_id) {
          if (competencies){
            element.display_name = getDisplayNameCompetency(element.career_step_competency_id);
          }
        }
        else if (element.career_step_skill_id){
          if (skills){
            element.display_name = getDisplayNameSkill(element.career_step_skill_id);
          }
        }
        else {
          element.display_name = "";
        }
        results_with_name.push(element);
        setUserStrengths(results_with_name)
      });
    }).catch((err) => {
      console.error("Failed to fetch user strengths: ", err);
    });
  };

  React.useEffect(() => {
    try {
      if (user && skills && competencies){
        fetchUserStrengths(user.user_id);
      }
    } catch (err) {
      console.error("Failed to retrieve user strenghts. ", err);
    }
  }, [user, skills, competencies]);


  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      <CardContent>
        <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
          Strengths
        </Typography>
        {strengthsopps.map((strengthopp, index) => (
          <Stack
            key={index}
            direction="row"
            sx={{ alignItems: 'center', gap: 2, pb: 2 }}
          >
            {index===0 && <Filter1OutlinedIcon />}
            {index===1 && <Filter2OutlinedIcon />}
            {index===2 && <Filter3OutlinedIcon />}
            {strengthopp.career_step_skill_id && (
              <Tooltip title="Skill" arrow><HandymanOutlinedIcon /></Tooltip>
            )}
            {strengthopp.career_step_competency_id && (
              <Tooltip title="Competency (soft skill)" arrow><Diversity3OutlinedIcon /></Tooltip>
            )}
            <Stack sx={{ gap: 1, flexGrow: 1 }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: '500' }}>
                {strengthopp.display_name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  rated {(strengthopp.average_rating/5*100).toFixed(0)}%
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                aria-label="value"
                value={strengthopp.average_rating/5*100}
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: "hsl(220, 25%, " + strengthopp.average_rating/5 + ")",
                  },
                }}
              />
            </Stack>
          </Stack>
        ))}
      </CardContent>
    </Card>
  );
}
