import React from "react";

import {Checkbox, Chip, Stack, TableCell, TableRow,} from "@mui/material";
import {convertDateToShort} from "../../utils/monthNames";
import { colorFromDate } from "../../utils/colorsFromDate";

interface KpiProps {
    kpiInfo: TeamGoalKpiInfo;
    onKpiCompleted: (step: TeamGoalKpiInfo, isCompleted: boolean) => void;
    viewLevelDetails: boolean;
}

export const KpiCard = ({kpiInfo, onKpiCompleted, viewLevelDetails}: KpiProps) => {
    async function handleKpiCompleted(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked; // Get the current value of the checkbox
        onKpiCompleted(kpiInfo, isChecked);
    }

    const backgroundColor = colorFromDate(
        kpiInfo.kpi_target_date? kpiInfo.kpi_target_date: "", 
        kpiInfo.kpi_achieved_date
    );

    return (

        <TableRow>
            <TableCell size="small" width="5%">
                <Checkbox id={`step-check- ${kpiInfo.kpi_id}`}
                          checked={!!kpiInfo.kpi_achieved_date} 
                          onChange={handleKpiCompleted}/>
            </TableCell>
            <TableCell align="left" sx={{padding: 1}}>
                <Stack direction={"column"} justifyContent="flex-end" spacing={0}>
                    <Stack direction={"row"}>
                        {kpiInfo.kpi_name}
                        <Chip color={backgroundColor} sx={{marginLeft: 1}}
                            label={kpiInfo.kpi_achieved_date? `completed ${convertDateToShort(kpiInfo.kpi_achieved_date)}` : `by ${convertDateToShort(kpiInfo.kpi_target_date)}`}
                            size="small"
                        />
                    </Stack>
                    {viewLevelDetails && 
                    <i>
                        Measurement (success criteria): {kpiInfo.kpi_measure_definition}
                        <br />
                        <Chip color="default" sx={{marginLeft: 1}}
                            label={`created on ${convertDateToShort(kpiInfo.kpi_created_date)}`}
                            size="small"
                        />
                        {kpiInfo.kpi_achieved_date && 
                            <Chip color={backgroundColor} sx={{marginLeft: 1}}
                                label={`target ${convertDateToShort(kpiInfo.kpi_target_date)}`}
                                size="small"
                            />
                        }
                        Target value: {kpiInfo.kpi_target_value} (weight: {kpiInfo.kpi_weight})
                    </i>
                    }
                </Stack>
            </TableCell>
        </TableRow>
    );
};