export const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

export type CompanySeeded = {
  company_name: string;
  company_domain?: string;
  company_id: number;
};
export const seedCompany = async (Email: string) => {
  try {
    const response = await fetch(`${PUBLIC_API_URL}/companies/seed`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_work_email: Email,
        user_name: Email, // Assuming user name is also the email for simplicity
      }),
    });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return response.json();
  } catch (err) {
    console.error(err);
  }
};
