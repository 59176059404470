import {
    Accordion,
    AccordionActions,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    Grid2,
    Paper,
    TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useState} from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import {StepEdit} from "./StepEdit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import {stepsEqual} from "../../utils/ActionPlanUtils";

interface ActionItemCardEditProps {
    plan: ActionItemInfo;
    onCancel: () => void;
    onSave: (item: ActionItemInfo) => void;
}

export const ActionPlanItemCardEdit = ({
                                           plan,
                                           onCancel,
                                           onSave,
                                       }: ActionItemCardEditProps) => {
    const [actionItem, setActionItem] = useState<ActionItemInfo>(plan);
    const [actionItemNameError, setActionItemNameError] = useState(false);
    const [priorityError, setPriorityError] = useState(false);

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value} = event.target;
        setActionItem((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }

    function handleOnActionItemNameChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setActionItemNameError(true)
        } else {
            setActionItemNameError(false)
        }
        setActionItem((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }
    function handleOnPriorityChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setPriorityError(true)
        } else {
            setPriorityError(false)
        }
        setActionItem((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }

    function handleOnStepChange(updatedStep: ActionItemStepInfo) {
        const updatedSteps = (actionItem.user_action_plan_steps || []).map((step) => {
            if (step.user_action_plan_step_id === updatedStep.user_action_plan_step_id) {
                return {...updatedStep};
            }
            return step;
        });
        setActionItem((prevData) => ({
            ...prevData,
            user_action_plan_steps: [...updatedSteps],
        }));
    }

    const handleDeleteStep = (deletedStep: ActionItemStepInfo) => {
        const updatedItem = {...actionItem};
        updatedItem.user_action_plan_steps = (actionItem.user_action_plan_steps || []).filter((step) => {
            return !stepsEqual(deletedStep, step);
        });
        setActionItem(updatedItem);
    }

    const getNewStepId = (steps?: ActionItemStepInfo[]) => -( (steps?.length ?? 0) + 1 );

    const handleAddStep = () => {
        const newStep: ActionItemStepInfo = {
            user_action_plan_id: actionItem.user_action_plan_id,
            // Set the user_action_plan_step_id to a negative value to indicate the new step
            user_action_plan_step_id: getNewStepId(actionItem.user_action_plan_steps),
            user_action_plan_step_description: "",
            user_action_plan_step_measurement: "",
            user_action_plan_step_target_date: "",
            user_action_plan_step_date_completed: null,
        };

        setActionItem((prevData) => ({
            ...prevData,
            user_action_plan_steps: [...(prevData.user_action_plan_steps || []), newStep],
        }));
    }

    async function onOK() {
        try {
            if (actionItem.user_action_plan_goal.length == 0){
                setActionItemNameError(true)
            } else {
                setActionItemNameError(false)
                if (actionItem.user_action_plan_priority.length == 0){
                    setPriorityError(true)
                } else {
                    setPriorityError(false)
                    onSave(actionItem);
                }
                }
        } catch (error) {
            // TODO: handle failure
            console.error(error);
        }
    }

    const actionItemEditTitle = (
        <div className="text-left" style={{width: '100%'}}>
            {/* Edit action item goal */}
            <Box sx={{p: 1}}>
                <FormControl fullWidth>
                    <TextField
                        size={"small"}
                        name="user_action_plan_goal"
                        label="Description"
                        required
                        defaultValue={actionItem.user_action_plan_goal}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onChange={handleOnActionItemNameChange}
                        error={actionItemNameError}
                        helperText={actionItemNameError ? "Please enter Description" : ""}
                    />
                </FormControl>
            </Box>
        </div>
    )

    const toolBarContent = (
        <>
            <Button variant="outlined" startIcon={<AddIcon/>} color={"secondary"} onClick={handleAddStep}>
                Add Step
            </Button>
            <Button variant="outlined" startIcon={<CancelIcon/>} color={"error"} onClick={onCancel}>
                Cancel
            </Button>
            <Button variant="outlined" startIcon={<CheckIcon/>} onClick={onOK}>
                OK
            </Button>
        </>
    )

    const actionItemEditFields = (
        <Stack direction={"row"} sx={{width: '100%', flex: 1}}>
            <Paper sx={{padding: 2, mb: 2, width: '100%'}}>
                {/*Edit action item priority, date completed, and target date*/}

                <FormControl fullWidth sx={{gap: 2}}>
                    <TextField
                        size={"small"}
                        name="user_action_plan_business_value"
                        label="Business Value"
                        defaultValue={actionItem.user_action_plan_business_value}
                        onChange={handleOnChange}
                        multiline
                    />
                </FormControl>
                <Grid2
                    sx={{
                        textAlign: "left",
                        mt: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                    }}
                >
                    <TextField
                        size={"small"}
                        name="user_action_plan_priority"
                        label="Priority"
                        type="number"
                        required
                        defaultValue={actionItem.user_action_plan_priority}
                        onChange={handleOnPriorityChange}
                        error={priorityError}
                        helperText={priorityError ? "Please enter Priority" : ""}
                    />
                    <TextField
                        size={"small"}
                        name="user_action_plan_date_completed"
                        label="Date Completed"
                        type="date"
                        defaultValue={actionItem.user_action_plan_date_completed}
                        onChange={handleOnChange}
                        slotProps={{inputLabel: {shrink: true}}}
                    />
                </Grid2>
            </Paper>
        </Stack>
    )

    const stepsFields = (
        <Stack spacing={2}>
            {
                (actionItem.user_action_plan_steps || []).map((step) => {
                    return <StepEdit stepInfo={step}
                                     key={step.user_action_plan_step_id}
                                     onChange={handleOnStepChange}
                                     onDeleteStep={handleDeleteStep}/>
                })
            }
        </Stack>
    );

    return <Accordion defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex justify-between"
        >
            {actionItemEditTitle}
        </AccordionSummary>
        <AccordionDetails>
            {actionItemEditFields}
            {stepsFields}
        </AccordionDetails>
        <AccordionActions>
            {toolBarContent}
        </AccordionActions>
    </Accordion>
}
