import { get } from "./helpers";
import { UserCareerStepSkill } from "./schemas/UserCareerStepSkill";

export const getUserCareerStepSkills = async (
  career_step_id: number
): Promise<UserCareerStepSkill[]> => {
  try {
    const params = { career_step_id: career_step_id.toString() };
    return await get<UserCareerStepSkill[]>(
      "/career_step_skills/career_step_id?" +
        new URLSearchParams(params).toString()
    );
  } catch (error) {
    console.error("Failed to retrieve user career step skills. ", error);
    return [];
  }
};
