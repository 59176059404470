export function sortActionPlans(a: ActionItemInfo, b: ActionItemInfo) {
    if (a.user_action_plan_priority < b.user_action_plan_priority) {
        return -1;
    } else if (a.user_action_plan_priority > b.user_action_plan_priority) {
        return 1;
    } else {
        // Priorities are the same, sort by date created
        if (a.user_action_plan_date_created && b.user_action_plan_date_created
            && (a.user_action_plan_date_created < b.user_action_plan_date_created)) {
            return -1;
        } else if (
            (a.user_action_plan_date_created && b.user_action_plan_date_created
                && (a.user_action_plan_date_created > b.user_action_plan_date_created))
        ) {
            return 1;
        } else {
            // date_created is the same, sorting Plan by Plan_ID
            if (a.user_action_plan_id < b.user_action_plan_id) {
                return -1;
            }
            if (a.user_action_plan_id > b.user_action_plan_id) {
                return 1;
            }
            return 0;
        }
    }
}

export function sortActionPlanSteps(a: ActionItemStepInfo, b: ActionItemStepInfo) {
    if (
        a.user_action_plan_step_target_date < b.user_action_plan_step_target_date
    ) {
        return -1;
    } else if (
        a.user_action_plan_step_target_date > b.user_action_plan_step_target_date
    ) {
        return 1;
    } else {
        return 0;
    }
}

export function stepsEqual(a: ActionItemStepInfo, b: ActionItemStepInfo) {
    return (
        a.user_action_plan_step_description === b.user_action_plan_step_description &&
        a.user_action_plan_step_measurement === b.user_action_plan_step_measurement &&
        a.user_action_plan_step_target_date === b.user_action_plan_step_target_date &&
        a.user_action_plan_step_date_completed === b.user_action_plan_step_date_completed &&
        a.user_action_plan_step_id === b.user_action_plan_step_id &&
        a.user_action_plan_id === b.user_action_plan_id
    );
}
