import { get } from "./helpers";
import { SkillRating } from "./schemas/SkillRating";

export const getSkillRating = async (
  skill_id: number,
): Promise<SkillRating[]> => {
  try {
    const params = {skill_id: skill_id.toString()};
    return await get<SkillRating[]>("/skill_ratings/skill_id?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};
