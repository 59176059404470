export function kpisEqual(a: TeamGoalKpiInfo, b: TeamGoalKpiInfo) {
    return (
        a.goal_id === b.goal_id &&
        a.kpi_achieved_date === b.kpi_achieved_date &&
        a.kpi_achieved_user_id === b.kpi_achieved_user_id &&
        a.kpi_created_date === b.kpi_created_date &&
        a.kpi_created_user_id === b.kpi_created_user_id &&
        a.kpi_measure_definition === b.kpi_measure_definition &&
        a.kpi_name === b.kpi_name &&
        a.kpi_status === b.kpi_status &&
        a.kpi_target_date === b.kpi_target_date &&
        a.kpi_target_value === b.kpi_target_value &&
        a.kpi_weight === b.kpi_weight &&
        a.kpi_id === b.kpi_id
    );
}
