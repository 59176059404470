import {FormControl, IconButton, Paper, Stack, TextField} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface KpiEditProps {
    kpiInfo: TeamGoalKpiInfo;
    onDeleteKpi: (step: TeamGoalKpiInfo) => void;
    onChange: (updatedKpi: TeamGoalKpiInfo) => void;
}

export const KpiEdit = ({
                             kpiInfo,
                             onDeleteKpi,
                             onChange,
                         }: KpiEditProps) => {
    const [kpiNameError, setKpiNameError] = useState(false);

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value} = event.target;
        const updatedKpi = {
            ...kpiInfo,
            [name]: value,
        };
        onChange(updatedKpi);
    }

    function handleChangeKpiName(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setKpiNameError(true)
        } else {
            setKpiNameError(false)
        }
        const updatedKpi = {
            ...kpiInfo,
            [name]: value,
        };
        onChange(updatedKpi);
    }

    const handleDeleteKpi = () => {
        onDeleteKpi(kpiInfo);
    }

    return (
        <Stack key={kpiInfo.kpi_id} direction={"row"} sx={{width: '100%'}}>
            <Paper sx={{padding: 2, flex: 1}}>
                <Stack spacing={2}>
                    <Stack spacing={4}>
                        <FormControl fullWidth>
                            <TextField
                                size={"small"}
                                id="kpi_name"
                                required
                                label="Kpi (or Key Result)"
                                defaultValue={kpiInfo.kpi_name}
                                name={"kpi_name"}
                                onChange={handleChangeKpiName}
                                error={kpiNameError}
                                helperText={kpiNameError ? "Please enter KPI (Key Result) name" : ""}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                size={"small"}
                                id="kpi_measure_definition"
                                label="Measurement (success criteria)"
                                defaultValue={kpiInfo.kpi_measure_definition}
                                name={"kpi_measure_definition"}
                                onChange={handleOnChange}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        <TextField
                            size={"small"}
                            id="kpi_target_value"
                            label="Target Value"
                            defaultValue={kpiInfo.kpi_target_value}
                            name={"kpi_target_value"}
                            slotProps={{inputLabel: {shrink: true}}}
                            onChange={handleOnChange}
                        />
                        <TextField
                            size={"small"}
                            id="kpi_weight"
                            label="Weight (out of 100)"
                            type="number"
                            defaultValue={kpiInfo.kpi_weight}
                            name={"kpi_weight"}
                            slotProps={{inputLabel: {shrink: true}}}
                            onChange={handleOnChange}
                        />
                        <TextField
                            size={"small"}
                            id="kpi_target_date"
                            label="Target Date"
                            type="date"
                            defaultValue={kpiInfo.kpi_target_date}
                            name={"kpi_target_date"}
                            slotProps={{inputLabel: {shrink: true}}}
                            onChange={handleOnChange}
                        />
                        <TextField
                            size={"small"}
                            id="kpi_achieved_date"
                            label="Date Achieved"
                            type="date"
                            defaultValue={kpiInfo.kpi_achieved_date}
                            name={"kpi_achieved_date"}
                            slotProps={{inputLabel: {shrink: true}}}
                            onChange={handleOnChange}
                        />
                    </Stack>
                </Stack>
            </Paper>
            <IconButton aria-label="delete" size="medium" color={"default"}
                        sx={{
                            ml: 'auto', flex: 'none', border: "none",     // Remove any applied border
                            outline: "none",    // Remove focus outline
                            "&:focus": {
                                outline: "none",  // Ensure focus styles are removed
                            }
                        }} onClick={handleDeleteKpi}>
                <DeleteIcon fontSize="small" color={"error"}/>
            </IconButton>
        </Stack>
    );
}
